import React from 'react';

const LogoSvg = (props) => {
	return (
		<svg viewBox="0 0 58 58" fill="none" {...props}>
			<path
				d="M30.5136 2.53065C37.7682 2.53065 44.3335 5.47232 49.0851 10.2247C53.8368 14.9803 56.778 21.5467 56.778 28.8024C56.778 36.0581 53.8368 42.6244 49.0851 47.38C44.3335 52.1324 37.765 55.0741 30.5136 55.0741C23.2591 55.0741 16.6937 52.1324 11.9421 47.38C7.18724 42.6276 4.24921 36.0581 4.24921 28.8024C4.24921 21.5467 7.19041 14.9803 11.9421 10.2247C16.6937 5.47232 23.2622 2.53065 30.5136 2.53065ZM48.3228 10.9903C43.7649 6.4317 37.4696 3.61392 30.5136 3.61392C23.5608 3.61392 17.2623 6.43487 12.7044 10.9903C8.14646 15.549 5.32913 21.8453 5.32913 28.7992C5.32913 35.7531 8.14646 42.0526 12.7044 46.6112C17.2623 51.1699 23.5576 53.9876 30.5136 53.9876C37.4664 53.9876 43.7649 51.1667 48.3228 46.6112C52.8807 42.0526 55.6981 35.7563 55.6981 28.7992C55.6981 21.8453 52.8807 15.5458 48.3228 10.9903Z"
				fill="white"
			/>
			<path
				d="M32.6138 2.88939C39.8208 2.88939 46.3448 5.78976 51.0647 10.4786C55.7878 15.1675 58.7099 21.6449 58.7099 28.799C58.7099 35.953 55.7878 42.4304 51.0647 47.1193C46.3416 51.8082 39.8176 54.7085 32.6138 54.7085C25.4069 54.7085 18.8829 51.8082 14.163 47.1193C9.43994 42.4304 6.51779 35.953 6.51779 28.799C6.51779 21.6449 9.43994 15.1675 14.163 10.4786C18.8861 5.78976 25.4101 2.88939 32.6138 2.88939ZM50.8137 10.7328C46.1574 6.11061 39.7223 3.25154 32.6138 3.25154C25.5054 3.25154 19.0735 6.11061 14.4139 10.7328C9.75757 15.355 6.87671 21.7434 6.87671 28.799C6.87671 35.8545 9.75757 42.243 14.4139 46.8651C19.0703 51.4873 25.5054 54.3496 32.6138 54.3496C39.7223 54.3496 46.1542 51.4905 50.8137 46.8651C55.4701 42.243 58.351 35.8545 58.351 28.799C58.351 21.7434 55.4701 15.3581 50.8137 10.7328Z"
				fill="white"
			/>
			<path
				d="M28.8514 0.974711C21.7842 0.974711 15.3873 4.08793 10.7563 9.12308C6.12534 14.1582 3.25719 21.1153 3.25719 28.803C3.25719 36.4876 6.12216 43.4447 10.7563 48.483C15.3873 53.5182 21.7842 56.6314 28.8514 56.6314C35.9185 56.6314 42.3155 53.5182 46.9465 48.483C51.5774 43.4479 54.4456 36.4908 54.4456 28.803C54.4456 21.1185 51.5806 14.1614 46.9465 9.12308C42.3155 4.08793 35.9185 0.974711 28.8514 0.974711ZM10.6515 9.0246C15.3079 3.96086 21.7429 0.828581 28.8514 0.828581C35.9598 0.828581 42.3949 3.96086 47.0513 9.0246C51.7077 14.0852 54.5885 21.0772 54.5885 28.7999C54.5885 36.5226 51.7077 43.5146 47.0513 48.5751C42.3949 53.6389 35.9598 56.7712 28.8514 56.7712C21.7429 56.7712 15.3079 53.6389 10.6515 48.5751C5.99511 43.5146 3.11426 36.5226 3.11426 28.7999C3.11426 21.0772 5.99511 14.0852 10.6515 9.0246Z"
				fill="white"
			/>
			<path
				d="M26.7567 1.80439C19.877 1.80439 13.6483 4.81595 9.13489 9.68591C4.60556 14.5718 1.80411 21.3287 1.80411 28.8004C1.80411 36.269 4.60556 43.0291 9.13489 47.9149C13.6483 52.7849 19.8801 55.7964 26.7567 55.7964C33.6365 55.7964 39.8651 52.7849 44.3785 47.9149C48.9079 43.0291 51.7093 36.2721 51.7093 28.8004C51.7093 21.3319 48.9079 14.5749 44.3785 9.68591C39.8651 4.81595 33.6333 1.80439 26.7567 1.80439ZM7.81675 8.45969C12.6605 3.23393 19.3592 0 26.7567 0C34.1574 0 40.8529 3.23393 45.6999 8.45969C50.5278 13.6696 53.5166 20.8617 53.5166 28.8004C53.5166 36.7391 50.5309 43.9313 45.6999 49.1412C40.8561 54.3669 34.1574 57.6008 26.7567 57.6008C19.3561 57.6008 12.6605 54.3669 7.81675 49.1412C2.98885 43.9313 0 36.7391 0 28.8004C0 20.8617 2.98567 13.6696 7.81675 8.45969Z"
				fill="white"
			/>
			<path
				d="M24.1926 19.9402H25.965V37.6601H24.1926V29.3815H14.575V37.6601H12.8026V19.9402H14.575V27.7105H24.1926V19.9402ZM46.9473 29.2322V30.1439C46.9473 32.3454 46.1627 34.1943 44.5937 35.6873C43.0246 37.2058 40.9219 37.9651 38.292 37.9651C35.6081 37.9651 33.3815 37.0787 31.6346 35.3061C29.8877 33.5335 29.0015 31.3574 29.0015 28.8001C29.0015 26.2428 29.8877 24.0668 31.6346 22.2941C33.3815 20.5215 35.6081 19.6352 38.292 19.6352C39.9373 19.6352 41.4301 20.0387 42.7991 20.8233C44.1649 21.608 45.2289 22.6722 45.9626 23.9874L44.3936 24.8991C43.8377 23.8095 43.0024 22.9486 41.9129 22.3164C40.8235 21.6842 39.6101 21.3538 38.292 21.3538C36.0909 21.3538 34.2677 22.0622 32.8765 23.5045C31.4853 24.9467 30.777 26.6939 30.777 28.797C30.777 30.8968 31.4853 32.644 32.8765 34.0894C34.2677 35.5317 36.0909 36.2401 38.292 36.2401C40.293 36.2401 41.8875 35.735 43.0754 34.7216C44.2665 33.7336 44.9494 32.4439 45.1273 30.9H38.0919V29.229H46.9505L46.9473 29.2322Z"
				fill="white"
			/>
		</svg>
	);
};

export default LogoSvg;
